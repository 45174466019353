import React from 'react'
import { graphql } from 'gatsby'
import {Link} from 'gatsby'
import Layout from 'components/layout'
import SEO from 'components/seo/seo'
import LayoutSidePages from "../components/layout/side-pages";
import GetInTouch from 'components/get-in-touch/get-in-touch'
import './company-policies.sass'


const getPolicies = (policies) => {
  return policies.map((policy, i) => {
    return (
      <article key={i} className="PolicyItem">
        <Link className="PolicyItem__link" to={policy.link}>
          <div className="PolicyItem__content px-4 pt-4">
            <h2 className="PolicyItem__title h4">
              {policy.title}
            </h2>
            <p className="PolicyItem__text">
              {policy.summary}
            </p>
          </div>
          <div className="PolicyItem__footer px-4 pb-4">
            <hr className="mt-0" />
            <div className="d-flex align-items-center PolicyItem__link-text">
              <span className="flex-grow-1 k-text--mono small">
                Read more
              </span>
              <span>&rarr;</span>
            </div>
          </div>
        </Link>
      </article>
    )
  })
}

const PoliciesPage = ({ data }) => {
  const d = data.mainYaml.policies

  return (
      <Layout
        bodyClass="k-reverse-header">
        <SEO
          title="Company Policies"
          keywords={['Kortical company policies', 'Artificial Intelligence']}
          description="Please find our published company policies" />
        <LayoutSidePages background="k-bg--grey pb-4">
          <h1 className="h2--new">Company Policies</h1>
          <p className="mb-4 Careers__subheading">Please find our published company policies</p>
        </LayoutSidePages>
        <section className="container-fluid">
          <div className="k-bg--white k-section">
            <div className="k-policy-grid my-5">
              {getPolicies(d)}
            </div>
          </div>
        </section>
        <GetInTouch />
      </Layout>
  )
}

export const pageQuery = graphql`
  query {
    mainYaml {
      policies {
        title
        summary
        link
      }
    }
  }
`

export default PoliciesPage
